<template>
  <div>
    <div class="news">
      <div class="news-title">
        <h1>超元创世 | 关于IP-BOX</h1>
        <span class="hr"></span>
      </div>
      <div class="news-module">
<!--        <div class="news-module-top" v-for="item in topArticleList" :key="item.id">-->
<!--          <div class="top-image" @click="pageJump('/detail',item.id)">-->
<!--            <div class="top-image-container" :style="{backgroundImage: 'url(' + item.vCover + ')'}"></div>-->
<!--            <div class="top-image-bg"></div>-->
<!--          </div>-->
<!--          <div class="top-content">-->
<!--            <div class="top-content-tag">新闻</div>-->
<!--            <div class="top-content-title" @click="pageJump('/detail',item.id)">{{ item.title }}</div>-->
<!--            <div class="top-content-text">{{ item.subTitle }}</div>-->
<!--          </div>-->
<!--          <div class="top-gradient"></div>-->
<!--        </div>-->
        <div class="news-module-list">
<!--          <div class="list-horizontal">-->
<!--            <div class="list-horizontal-container" v-for="item in twoArticleList" :key="item.id">-->
<!--              <div class="container-cover" @click="pageJump('/detail',item.id)">-->
<!--                <img :src="item.vCover" alt="">-->
<!--              </div>-->
<!--              <div class="container-content">-->
<!--                <div class="container-content-tag">新闻</div>-->
<!--                <h2 @click="pageJump('/detail',item.id)">{{ item.title }}</h2>-->
<!--                <div class="container-content-text">{{ item.subTitle }}</div>-->
<!--              </div>-->
<!--              <div class="container-feed">-->
<!--                <div class="container-feed-tag">新闻</div>-->
<!--                <div class="container-feed-time">-->
<!--                  <span>{{ item.updateTime.substring(0,item.updateTime.indexOf(' ')) }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="list-vertical">
            <div class="list-vertical-container" v-for="item in threeArticleList" :key="item.id">
              <div class="container-cover" @click="pageJump('/detail',item.id)">
                <img :src="item.vCover" alt="">
              </div>
              <div class="container-content">
                <div class="container-content-top">
                  <div class="top-tag">新闻</div>
                  <div class="top-time">
                    <span>{{ item.updateTime.substring(0,item.updateTime.indexOf(' ')) }}</span>
                  </div>
                </div>
                <h2 @click="pageJump('/detail',item.id)">{{ item.title }}</h2>
                <div class="container-content-bot">{{ item.subTitle }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../utils/api";

export default {
  data() {
    return {
      topArticleList: [],
      twoArticleList: [],
      threeArticleList: [],
    }
  },
  mounted() {
    this.getArticleList();
  },
  methods: {
    getArticleList() {
      api.getArticleData({
        current: 1,
        size: 0,
        type: 1,
        isPublish: 1,
      }).then(res => {
        if (res.data.code === 200) {
          console.log(res.data.data.records)
          res.data.data.records.forEach(item => {
            if (item.layoutType === 1) {
              this.topArticleList.push(item);
            } else if (item.layoutType === 2) {
              this.twoArticleList.push(item);
            } else {
              this.threeArticleList.push(item);
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
//pc端
@media only screen and (min-device-width: 700px) {
  .news {
    width: 100%;
    min-height: 100vh;
    background: rgb(14, 17, 40);
    margin-top: 52px;
    overflow: visible;
    position: relative;

    .news-title {
      width: auto;
      max-width: 1456px;
      margin: 0 auto;

      h1 {
        padding: 40px 10px 0;
        box-sizing: border-box;
        color: rgb(219, 222, 227);
        font-size: 1.5rem;
        font-weight: 700;
      }

      .hr {
        width: 100%;
        margin-bottom: 40px;
        padding: 0.75rem;
        border-bottom: 1px solid rgb(51, 51, 51);
        box-sizing: border-box;
        display: block;
      }
    }

    .news-module {
      width: auto;
      max-width: 1456px;
      margin: 0 auto;


      .news-module-top {
        width: 100%;
        margin-bottom: 32px;
        position: relative;
        overflow: hidden;

        .top-image {
          width: 100%;
          height: 1px;
          padding-bottom: 56.25%;
          box-sizing: border-box;
          position: relative;
          top: 0;
          left: 0;
          transition: all 350ms ease-in-out 0s;
          cursor: pointer;

          .top-image-container {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center 0;
            background-repeat: no-repeat;
            margin: 0 auto;
            position: absolute;
            top: 0;
            left: 0;
          }

          .top-image-bg {
            //width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 80%, rgba(0, 0, 0, 0.85) 90%, rgba(0, 0, 0, 0.95) 100%);
            padding-bottom: 56.25%;
            box-sizing: border-box;
            position: relative;
          }
        }

        .top-content {
          padding: 20px 20px 0 0;
          box-sizing: border-box;
          position: absolute;
          bottom: 25px;
          left: 20px;
          z-index: 2;

          .top-content-tag {
            width: 45px;
            height: 28px;
            margin-bottom: 10px;
            background-color: #00adef;
            padding: 0 10px;
            box-sizing: border-box;
            border-radius: 20px;
            color: #ffffff;
            font-size: 12px;
            line-height: 28px;
            cursor: pointer;
          }

          .top-content-tag:hover {
            opacity: 0.9;
          }

          .top-content-title {
            padding: 20px 0 60px;
            box-sizing: border-box;
            text-align: left;
            color: #ffffff;
            font-size: 48px;
            font-weight: 400;
            line-height: 20px;
          }

          .top-content-text {
            color: rgb(219, 222, 227);
            font-size: 14px;
          }
        }

        .top-gradient {
          display: none;
        }
      }

      .news-module-list {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;


        .list-horizontal {
          width: calc(100% + 32px);
          margin: -16px;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;

          .list-horizontal-container {
            max-width: 50%;
            flex-basis: 50%;
            flex-grow: 0;
            margin-bottom: 32px;
            padding: 16px;
            box-sizing: border-box;

            .container-cover {
              width: 100%;
              max-height: 320px;
              overflow: hidden;
              position: relative;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 50%;
                transition: all 350ms ease-in-out 0s;
                cursor: pointer;
              }

              img:hover {
                transform: scale(1.03);
              }
            }

            .container-content {
              width: 100%;
              min-height: 250px;
              padding: 40px 20px 0 0;
              box-sizing: border-box;
              position: relative;

              .container-content-tag {
                width: 45px;
                height: 28px;
                margin-bottom: 10px;
                background-color: #00adef;
                padding: 0 10px;
                box-sizing: border-box;
                border-radius: 20px;
                color: #ffffff;
                font-size: 12px;
                line-height: 28px;
                cursor: pointer;
              }

              .container-content-tag:hover {
                opacity: 0.9;
              }

              h2 {
                margin: 5px 0 20px;
                color: #ffffff;
                font-weight: 400;
                font-size: 26px;
                line-height: 34px;
                cursor: pointer;
              }

              h2:hover {
                color: rgb(219, 222, 227);
              }

              .container-content-text {
                color: rgb(219, 222, 227);
                font-size: 14px;
                line-height: 24px;
              }
            }

            .container-feed {
              display: none;
            }
          }
        }

        .list-vertical {
          width: calc(100% + 32px);
          margin: -16px;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;

          .list-vertical-container {
            max-width: 100%;
            flex-basis: 100%;
            flex-grow: 0;
            padding: 16px 16px 68px 16px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .container-cover {
              width: 290px;
              height: 170px;
              padding: 8px;
              //box-sizing: border-box;
              cursor: pointer;

              img {
                width: 290px;
                height: 170px;
                object-fit: cover;
              }
            }

            .container-content {
              max-width: 100%;
              flex-grow: 1;
              flex-basis: 0;
              padding: 8px;
              box-sizing: border-box;

              .container-content-top {
                width: 100%;
                padding-top: 20px;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .top-tag {
                  width: 45px;
                  height: 28px;
                  background-color: #00adef;
                  margin: 0 10px 10px 0;
                  padding: 0 10px;
                  box-sizing: border-box;
                  border-radius: 20px;
                  color: #ffffff;
                  font-size: 12px;
                  line-height: 28px;
                  cursor: pointer;
                }

                .top-tag:hover {
                  opacity: 0.9;
                }

                .top-time {
                  height: 28px;
                  margin: 0 10px 10px 0;
                  line-height: 28px;
                  padding: 0 10px;
                  box-sizing: border-box;

                  span {
                    display: inline-block;
                    color: rgb(202, 205, 209);
                    font-size: 12px;
                    margin-left: 15px;
                    position: relative;
                  }

                  span::before {
                    width: 1px;
                    height: 28px;
                    content: "";
                    background-color: rgb(84, 85, 86);
                    position: absolute;
                    left: -12px;
                    top: 0;
                  }
                }
              }

              h2 {
                margin: 5px 0 20px;
                color: #ffffff;
                font-weight: 400;
                font-size: 26px;
                line-height: 34px;
                cursor: pointer;
              }

              h2:hover {
                color: rgb(219, 222, 227);
              }

              .container-content-bot {
                color: rgb(219, 222, 227);
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}

//移动端
@media only screen and (max-device-width: 700px) {
  .news {
    width: 100%;
    background: rgb(14, 17, 40);
    margin-top: 52px;
    overflow: visible;
    position: relative;

    .news-title {
      width: 100%;

      h1 {
        padding: 40px 10px 0;
        box-sizing: border-box;
        color: rgb(219, 222, 227);
        font-size: 24px;
        font-weight: 700;
      }

      .hr {
        width: 100%;
        margin-bottom: 40px;
        padding: 12px;
        border-bottom: 1px solid rgb(51, 51, 51);
        box-sizing: border-box;
        display: block;
      }
    }

    .news-module {
      width: 100%;

      .news-module-top {
        width: 100%;
        margin-bottom: 32px;
        position: relative;
        overflow: hidden;

        .top-image {
          width: 100%;
          height: 1px;
          padding-bottom: 56.25%;
          box-sizing: border-box;
          position: relative;
          top: 0;
          left: 0;

          .top-image-container {
            width: 100%;
            height: 100%;
            background-image: url("https://cdn2.unrealengine.com/unreal-engine-5-1-feed-1920x1080-6b5464ec286c.png?resize=1&w=1300");
            background-position: center 0;
            background-repeat: no-repeat;
            background-size: cover;
            margin: 0 auto;
            position: absolute;
            top: 0;
            left: 0;
          }

          .top-image-bg {
            //width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.75) 80%, rgba(0, 0, 0, 0.85) 90%, rgba(0, 0, 0, 0.95) 100%);
            padding-bottom: 56.25%;
            box-sizing: border-box;
            position: relative;
          }
        }

        .top-content {
          padding: 20px 10px;
          box-sizing: border-box;
          position: absolute;
          bottom: unset;
          top: 0;
          z-index: 2;

          .top-content-tag {
            width: 45px;
            height: 28px;
            margin-bottom: 10px;
            background-color: #00adef;
            padding: 0 10px;
            box-sizing: border-box;
            border-radius: 20px;
            color: #ffffff;
            font-size: 12px;
            line-height: 28px;
          }

          .top-content-title {
            padding: 5px 0 20px;
            box-sizing: border-box;
            text-align: left;
            color: #ffffff;
            font-size: 24px;
            font-weight: 400;
            line-height: 20px;
          }

          .top-content-text {
            color: rgb(219, 222, 227);
            font-size: 14px;
          }
        }

        .top-gradient {
          width: 100%;
          height: 100px;
          background: linear-gradient(transparent, rgb(0, 0, 0));
          position: absolute;
          bottom: 0;
          z-index: 2;
        }
      }

      .news-module-list {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;

        .list-horizontal {
          width: calc(100% + 32px);
          margin: -16px;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;

          .list-horizontal-container {
            max-width: 100%;
            flex-basis: 100%;
            flex-grow: 0;
            margin-bottom: 32px;
            padding: 16px;
            box-sizing: border-box;

            .container-cover {
              width: 100%;
              height: 200px;
              max-height: 320px;
              padding-top: 10px;
              overflow: hidden;
              position: relative;

              img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                object-position: 50% 50%;
              }
            }

            .container-content {
              width: 100%;
              padding: 20px 20px 0 0;
              box-sizing: border-box;
              position: relative;

              .container-content-tag {
                display: none;
              }

              h2 {
                margin: 5px 0 15px;
                color: #ffffff;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
              }

              .container-content-text {
                display: none;
              }
            }

            .container-feed {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 10px 20px 10px 0;
              box-sizing: border-box;

              .container-feed-tag {
                width: 45px;
                height: 28px;
                margin-right: 10px;
                background-color: #00adef;
                padding: 0 10px;
                box-sizing: border-box;
                border-radius: 20px;
                color: #ffffff;
                font-size: 12px;
                line-height: 28px;
              }

              .container-feed-time {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                span {
                  display: inline-block;
                  color: rgb(202, 205, 209);
                  font-size: 12px;
                  margin-left: 15px;
                  position: relative;
                }

                span::before {
                  width: 1px;
                  height: 28px;
                  content: "";
                  background-color: rgb(84, 85, 86);
                  position: absolute;
                  left: -12px;
                  top: -5px;
                }
              }
            }
          }
        }

        .list-vertical {
          width: 100%;

          .list-vertical-container {
            width: 100%;
            margin-bottom: 32px;
            padding: 16px 0;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .container-cover {
              width: 130px;
              height: 130px;
              padding: 8px 8px 8px 0;

              img {
                width: 130px;
                height: 130px;
                object-fit: cover;
                object-position: 50% 50%;
              }
            }

            .container-content {
              max-width: 100%;
              flex-grow: 1;
              flex-basis: 0;
              padding: 20px 20px 8px 8px;
              box-sizing: border-box;

              .container-content-top {
                //width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .top-tag {
                  width: 45px;
                  height: 28px;
                  background-color: #00adef;
                  margin: 0 10px 10px 0;
                  padding: 0 10px;
                  box-sizing: border-box;
                  border-radius: 20px;
                  color: #ffffff;
                  font-size: 12px;
                  line-height: 28px;
                }

                .top-time {
                  height: 28px;
                  margin: 0 10px 10px 0;
                  line-height: 28px;
                  padding: 0 10px;
                  box-sizing: border-box;

                  span {
                    display: inline-block;
                    color: rgb(202, 205, 209);
                    font-size: 12px;
                    margin-left: 15px;
                    position: relative;
                  }

                  span::before {
                    width: 1px;
                    height: 28px;
                    content: "";
                    background-color: rgb(84, 85, 86);
                    position: absolute;
                    left: -12px;
                    top: 0;
                  }
                }
              }

              h2 {
                margin: 5px 0 15px;
                color: #ffffff;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
              }

              .container-content-bot {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
